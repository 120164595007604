import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { format } from 'date-fns';
import { enUS, fi } from 'date-fns/locale';

export const fallbackLng = 'en';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,

    ns: ['translation', 'forms', 'recipes'],

    fallbackLng: 'en',
    whitelist: ['en', 'fi'],
  });

export default i18n;

export const languages = [
  {
    key: 'en',
    name: 'translation:en',
    locale: enUS,
  },
  {
    key: 'fi',
    name: 'translation:fi',
    locale: fi,
  },
];

let selectedLocale;

i18n.on('languageChanged', (lng) => {
  const found = languages.find((ln) => ln.key === lng);
  if (found) {
    selectedLocale = found.locale;
  } else {
    selectedLocale = enUS;
  }
});

// wrapper date-fns
export const formatDate = (date, formatStr = 'PP') => {
  if (!selectedLocale) {
    selectedLocale = enUS;
  }
  return format(date, formatStr, {
    locale: selectedLocale,
  });
};
