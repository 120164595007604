import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';

import urls, { postData, putData } from '../../utils/static';
import {
  selectActive,
  setActiveIngrediants,
  setDraftState,
  setActiveShoppingList,
  updateShoppingList,
  addShoppingList,
} from '../../redux/shoppinglist/shoppinglist.slice';
import { selectUser, selectToken } from '../../redux/auth/auth.slice';
import ShoppingListForm from '../../forms/shoppingListForm.component';

const ActiveListItemEdit = () => {
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState('');

  const user = useSelector(selectUser);
  const token = useSelector(selectToken);
  const activeList = useSelector(selectActive);
  const copyedList = JSON.parse(JSON.stringify(activeList));
  const initialValues = {
    name: copyedList.name,
    ingrediants: copyedList.ingrediants,
  };

  const saveList = async (values, saveAsDraft) => {
    if (loading) {
      return;
    }
    setLoading(true);
    setErrorText('');

    try {
      if (saveAsDraft) {
        if (saveAsDraft) {
          dispatch(setActiveIngrediants(values));
          navigate('/shoppinglist/active');
        }
      } else {
        if (activeList.shoppingList && user._id === activeList.shoppingList.creator._creatorId) {
          // edit
          const list = await putData(urls.shoppingList.edit + activeList.shoppingList._id, values, {
            Authorization: `Bearer ${token}`,
          });
          setLoading(false);
          dispatch(setActiveIngrediants(values));
          dispatch(setDraftState(false));
          dispatch(updateShoppingList(list));

          navigate('/shoppinglist/active');
        } else {
          const list = await postData(urls.shoppingList.add, values, { Authorization: `Bearer ${token}` });
          setLoading(false);
          dispatch(addShoppingList(list));
          dispatch(setActiveShoppingList(list));

          navigate('/shoppinglist/active');
        }
      }
    } catch (error) {
      setLoading(false);
      setErrorText(t('shoppingListSaveFailed'));
    }
  };

  const cancelEdit = (values) => {
    navigate('/shoppinglist/active');
  };

  return (
    <Grid container>
      <Grid item xs={12} sx={{ marginTop: '4rem' }}>
        <ShoppingListForm onSave={saveList} onCancel={cancelEdit} initialValues={initialValues} errorText={errorText} />
      </Grid>
    </Grid>
  );
};
export default ActiveListItemEdit;
