import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';

import urls, { postData } from '../../utils/static';
import { setActiveIngrediants, setDraftState, addShoppingList } from '../../redux/shoppinglist/shoppinglist.slice';
import { selectToken } from '../../redux/auth/auth.slice';
import ShoppingListForm from '../../forms/shoppingListForm.component';

const NewShoppingList = () => {
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState('');

  const token = useSelector(selectToken);

  const saveList = async (values) => {
    if (loading) {
      return;
    }
    setLoading(true);
    setErrorText('');

    try {
      const list = await postData(urls.shoppingList.add, values, { Authorization: `Bearer ${token}` });
      setLoading(false);
      dispatch(setActiveIngrediants(values));
      dispatch(setDraftState(false));
      dispatch(addShoppingList(list));

      navigate('/shoppinglist/me');
    } catch (error) {
      setLoading(false);
      setErrorText(t('shoppingListSaveFailed'));
    }
  };

  const cancelEdit = () => {
    navigate('/shoppinglist/me');
  };

  return (
    <Grid container>
      <Grid item xs={12} sx={{ marginTop: '4rem' }}>
        <ShoppingListForm onSave={saveList} onCancel={cancelEdit} errorText={errorText} hideDraft />
      </Grid>
    </Grid>
  );
};
export default NewShoppingList;
