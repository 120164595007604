import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';

import { selectActive, setActiveShoppingList } from '../../redux/shoppinglist/shoppinglist.slice';

const UseShoppingListDialog = ({ shoppinglist, onClose }) => {
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeList = useSelector(selectActive);
  const [openDialog, setOpenDialog] = useState(false);

  const handleDialogClose = useCallback(() => {
    setOpenDialog(false);
    onClose();
  }, [setOpenDialog, onClose]);

  const selectShoppingList = useCallback(() => {
    dispatch(setActiveShoppingList(shoppinglist));
    handleDialogClose();
    navigate('/shoppinglist/active');
  }, [dispatch, shoppinglist, handleDialogClose, navigate]);

  useEffect(() => {
    if (activeList.isActiveShoppingList && activeList.draft) {
      setOpenDialog(true);
    } else {
      selectShoppingList();
    }
  }, [activeList, selectShoppingList]);

  return (
    <Dialog open={openDialog} maxWidth="sm" fullWidth>
      <DialogTitle id="alert-dialog-title">{t('doYouWantToUseShoppingList')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('youHaveAlreadyUnsavedShoppingListSelected')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={selectShoppingList} autoFocus variant="contained">
          {t('yes')}
        </Button>

        <Button size="small" onClick={handleDialogClose}>
          {t('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default UseShoppingListDialog;
