import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { map, switchMap, takeUntil, catchError, filter, take } from 'rxjs/operators';

import urls, { postData } from '../../utils/static';
import {
  login,
  loginCancel,
  loginFullfilled,
  loginRejected,
  refresh,
  refreshFullfilled,
  refreshRejected,
} from './auth.slice';

const loginEpic = (action$) =>
  action$.pipe(
    ofType(login.type),
    map((action$) => {
      return {
        username: action$?.payload?.username,
        password: action$?.payload?.password,
      };
    }),
    switchMap((loginData) =>
      from(postData(urls.login, loginData)).pipe(
        map((response) => fetchFullfilled(response)),
        takeUntil(
          action$.pipe(
            filter((action) => action.type === loginCancel.type),
            take(1)
          )
        ),
        catchError((error) =>
          of({
            type: loginRejected.type,
            payload: error,
            error: true,
          })
        )
      )
    )
  );

const fetchFullfilled = (payload) => {
  return {
    type: loginFullfilled.type,
    payload: payload,
  };
};

export const refreshEpic = (action$) =>
  action$.pipe(
    ofType(refresh.type),
    switchMap(() =>
      from(postData(urls.refresh)).pipe(
        map((response) => refreshEpicFullfilled(response)),
        takeUntil(
          action$.pipe(
            filter((action) => action.type === loginCancel.type),
            take(1)
          )
        ),
        catchError((error) =>
          of({
            type: refreshRejected.type,
            payload: error,
            error: true,
          })
        )
      )
    )
  );

const refreshEpicFullfilled = (payload) => {
  return {
    type: refreshFullfilled.type,
    payload: payload,
  };
};

export default loginEpic;
