import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { Grid, Link } from '@mui/material';

export default function IngrediantsList({ ingrediants }) {
  return (
    <List
      sx={{
        width: '100%',
      }}
    >
      {ingrediants.map((ing, i) => (
        <ListItem key={i} divider>
          <Grid item xs={3}>
            <ListItemText xs={3} primary={`${ing.amount ?? ''} ${ing.unit}`.trim()} />
          </Grid>
          <Grid item xs={9}>
            {ing.productUrl ? (
              <Link href={ing.productUrl} target="_blank" rel="noopener" underline="hover">
                {ing.name}
              </Link>
            ) : (
              <ListItemText primary={ing.name} />
            )}
          </Grid>
        </ListItem>
      ))}
    </List>
  );
}
