import React, { useCallback, useMemo, useState } from 'react';
import { useField } from 'formik';
import isHotkey from 'is-hotkey';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact } from 'slate-react';

import { FormControl, FormHelperText, Paper } from '@mui/material';

import { HOTKEYS, Element, Leaf, toggleMark } from './editor.utils';

import SlateToolbar from './editor.toolbar.component';

const SlateInputField = ({ initValue, required, fullWidth, placeholder, textContainerStyle, ...props }) => {
  const [field, meta, helpers] = useField(props.field.name);
  const { setValue } = helpers;

  const [slateValue, setSlateValue] = useState(JSON.parse(initValue));

  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const errorMessage = meta.touched && meta.error ? meta.error : '';
  return (
    <FormControl error={Boolean(errorMessage)} required={required} fullWidth={fullWidth}>
      <Paper elevation={2} sx={{ padding: '1rem' }}>
        <Slate
          editor={editor}
          value={slateValue}
          onChange={(value) => {
            setSlateValue(value);
            setValue(JSON.stringify(value));
          }}
        >
          <SlateToolbar />

          <Editable
            id={field.name}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder={placeholder}
            onKeyDown={(event) => {
              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event)) {
                  event.preventDefault();
                  const mark = HOTKEYS[hotkey];
                  toggleMark(editor, mark);
                }
              }
            }}
            style={{ ...textContainerStyle }}
          />
        </Slate>
        <FormHelperText id={`${field.name}-error-text`}>{errorMessage}</FormHelperText>
      </Paper>
    </FormControl>
  );
};

export default SlateInputField;
