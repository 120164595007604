import { createSlice, createSelector } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'settings',
  initialState: {
    darkTheme: false,
  },
  reducers: {
    toggleTheme: (state) => {
      return {
        ...state,
        darkTheme: !state.darkTheme,
      };
    },
  },
});

export const { toggleTheme } = slice.actions;

const getSettings = (state) => state.settings;
export const selectDarkTheme = createSelector([getSettings], (settings) => settings.darkTheme);

export default slice.reducer;
