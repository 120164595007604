import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isBefore, sub } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, Tooltip, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';

import { selectActive, createDraftListItem, addRecipeActiveItem } from '../../redux/shoppinglist/shoppinglist.slice';

const AddRecipeToShoppingList = ({ recipe, onClose }) => {
  const { t } = useTranslation(['translation']);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeList = useSelector(selectActive);
  const [openDialog, setOpenDialog] = useState(false);
  const [needNewShoppingList, setNeedNewShoppingList] = useState(false);

  const handleClickWithAction = useCallback(
    (text) => {
      enqueueSnackbar(text, {
        variant: 'default',
        action: (key) => (
          <React.Fragment>
            <Tooltip title={t('goToShoppingList')}>
              <Button size="small" onClick={() => navigate('/shoppinglist/active')}>
                {t('go')}
              </Button>
            </Tooltip>
            <IconButton size="small" color="primary" onClick={() => closeSnackbar(key)}>
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        ),
      });
    },
    [enqueueSnackbar, closeSnackbar, t, navigate]
  );

  const handleDialogClose = useCallback(() => {
    setOpenDialog(false);
    onClose();
  }, [setOpenDialog, onClose]);

  const addRecipe = useCallback(() => {
    dispatch(addRecipeActiveItem(recipe));
    handleClickWithAction(t('ingrediantsAdded'));
    handleDialogClose();
  }, [dispatch, handleDialogClose, recipe, handleClickWithAction, t]);

  const createNew = () => {
    dispatch(createDraftListItem(recipe));
    handleClickWithAction(t('newListWithIngrediants'));
    handleDialogClose();
  };

  useEffect(() => {
    if (!activeList.isActiveShoppingList) {
      setNeedNewShoppingList(true);
      setOpenDialog(true);
    } else if (
      isBefore(
        new Date(activeList.lastEditedTime),
        sub(new Date(), {
          hours: 2,
        })
      )
    ) {
      setNeedNewShoppingList(false);
      setOpenDialog(true);
    } else {
      addRecipe();
    }
  }, [activeList, addRecipe]);

  return (
    <Dialog open={openDialog} maxWidth="sm" fullWidth>
      <DialogTitle id="alert-dialog-title">{t('addRecipeToShoppingList')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {needNewShoppingList ? t('youDontHaveActiveShoppingList') : t('YouHaveOldShoppingListActive')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={createNew} autoFocus variant="contained">
          {t('createNew')}
        </Button>
        {needNewShoppingList ? null : (
          <Button size="small" onClick={addRecipe} variant="contained">
            {t('useOldList')}
          </Button>
        )}
        <Button size="small" onClick={handleDialogClose}>
          {t('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AddRecipeToShoppingList;
