import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { combineEpics } from 'redux-observable';
import { catchError } from 'rxjs/operators';
import storage from 'redux-persist/lib/storage';

import loginEpic, { refreshEpic } from './auth/login.epics';
import signUpEpic from './auth/signup.epics';
import userEpic from './auth/user.epics';
import logoutEpic from './auth/logout.epics';
import getMyRecipesEpic from './myRecipe/getMyRecipes.epics';
import getRecipesEpic, { getRecipesPaginationEpic } from './recipe/getRecipes.epics';
import getShoppingListsEpic from './shoppinglist/shoppinglist.epics';

import appReducer from './app/app.slice';
import authReducer from './auth/auth.slice';
import settingsReducer from './settings/settings.slice';
import myRecipeReducer from './myRecipe/myRecipe.slice';
import recipeReducer from './recipe/recipes.slice';
import shoppingListReducer from './shoppinglist/shoppinglist.slice';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['shoppinglists'],
  whitelist: ['settings'],
};

const shoppingListPersistConfig = {
  key: 'shoppinglists',
  storage: storage,
  whitelist: ['active'],
};

const rootReducer = combineReducers({
  settings: settingsReducer,
  app: appReducer,
  auth: authReducer,
  myrecipe: myRecipeReducer,
  recipes: recipeReducer,
  shoppinglists: persistReducer(shoppingListPersistConfig, shoppingListReducer),
});

const epics = [
  loginEpic,
  refreshEpic,
  signUpEpic,
  userEpic,
  logoutEpic,
  getMyRecipesEpic,
  getRecipesEpic,
  getRecipesPaginationEpic,
  getShoppingListsEpic,
];

// GLOBAL ERROR HANDLING
export const rootEpic = (action$, store$, dependencies) =>
  combineEpics(...epics)(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      console.error(error);
      return source;
    })
  );

export default persistReducer(persistConfig, rootReducer);
