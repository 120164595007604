import { createSlice, createSelector } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'myrecipe',
  initialState: {
    draftRecipe: null,
    myRecipes: [],
    myRecipesLoaded: false,
    my: {
      loading: false,
      error: false,
    },
  },
  reducers: {
    // get
    get: (state) => {
      return {
        ...state,
        my: {
          loading: true,
          error: false,
        },
      };
    },
    getCancel: (state) => {
      state.my.loading = false;
      state.myRecipesLoaded = true;
    },
    getFullfilled: (state, payload) => {
      return {
        ...state,
        myRecipes: payload.payload,
        myRecipesLoaded: true,
        my: {
          ...state.my,
          loading: false,
        },
      };
    },
    getRejected: (state) => {
      return {
        ...state,
        myRecipesLoaded: true,
        my: {
          ...state.my,
          loading: false,
          error: true,
        },
      };
    },
    addRecipe: (state, recipe) => {
      return {
        ...state,
        draftRecipe: null,
        myRecipes: [recipe.payload, ...state.myRecipes],
      };
    },
    updateRecipe: (state, recipe) => {
      const myRecipes = state.myRecipes.map((r) => {
        if (r._id === recipe.payload._id) {
          r = recipe.payload;
        }
        return r;
      });

      return {
        ...state,
        draftRecipe: null,
        myRecipes: myRecipes,
      };
    },
    removeRecipe: (state, payload) => {
      return {
        ...state,
        draftRecipe: null,
        myRecipes: state.myRecipes.filter((r) => r._id !== payload.payload),
      };
    },
    newDraftRecipe: (state, recipe) => {
      return {
        ...state,
        draftRecipe: recipe.payload,
      };
    },
    clearDraftRecipe: (state) => {
      return {
        ...state,
        draftRecipe: null,
      };
    },
  },
});

export const {
  get,
  getCancel,
  getFullfilled,
  getRejected,
  addRecipe,
  updateRecipe,
  removeRecipe,
  newDraftRecipe,
  clearDraftRecipe,
} = slice.actions;

const getRecipe = (state) => state.myrecipe;

export const selectMyRecipes = createSelector([getRecipe], (recipe) => recipe.myRecipes);
export const isMyRecipesLoaded = createSelector([getRecipe], (recipe) => recipe.myRecipesLoaded);

export const selectDraftRecipe = createSelector([getRecipe], (recipe) => recipe.draftRecipe);

export default slice.reducer;
