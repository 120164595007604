import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton, Tooltip } from '@mui/material';

import { selectUser } from '../../redux/auth/auth.slice';

const RecipeMenuItem = ({ recipe, addShoppingList }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['translation']);
  const user = useSelector(selectUser);

  const handleAddShoppingList = () => {
    addShoppingList(recipe);
  };

  const handleRecipeView = () => {
    navigate(`/recipes/${recipe._id}`, { state: recipe });
  };
  const handleRecipeEdit = () => {
    navigate(`/recipes/${recipe._id}/edit`, { state: recipe });
  };

  return (
    <React.Fragment>
      <Tooltip title={t('addShoppinglist')}>
        <IconButton onClick={handleAddShoppingList}>
          <PlaylistAddIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('view')}>
        <IconButton onClick={handleRecipeView}>
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
      {user?._id === recipe.creator._creatorId ? (
        <Tooltip title={t('edit')}>
          <IconButton onClick={handleRecipeEdit}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </React.Fragment>
  );
};

export default RecipeMenuItem;
