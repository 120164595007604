import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import CodeIcon from '@mui/icons-material/Code';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import { useSlate } from 'slate-react';

import Divider from '@mui/material/Divider';
import { Grid } from '@mui/material';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
// import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import { isBlockActive, isMarkActive, toggleBlock, toggleMark } from './editor.utils';

const BlockButton = ({ format, children }) => {
  const editor = useSlate();
  return (
    <ToggleButton
      tabIndex={-1}
      value={format}
      selected={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {children}
    </ToggleButton>
  );
};

const MarkButton = ({ format, children }) => {
  const editor = useSlate();
  return (
    <ToggleButton
      tabIndex={-1}
      value={format}
      selected={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {children}
    </ToggleButton>
  );
};

const SlateToolbar = () => {
  return (
    <Grid container style={{ marginBottom: '.7rem' }}>
      <ToggleButtonGroup size="small" arial-label="text formatting" style={{ marginRight: '.7rem' }}>
        {MarkButton({ format: 'bold', children: <FormatBoldIcon /> })}
        {MarkButton({
          format: 'italic',
          children: <FormatItalicIcon />,
        })}
        {MarkButton({
          format: 'underline',
          children: <FormatUnderlinedIcon />,
        })}
        {MarkButton({
          format: 'code',
          children: <CodeIcon />,
        })}
      </ToggleButtonGroup>
      <Divider orientation="vertical" />
      <ToggleButtonGroup size="small" arial-label="text formatting" exclusive>
        {BlockButton({ format: 'heading-one', children: <LooksOneIcon /> })}
        {BlockButton({ format: 'heading-two', children: <LooksTwoIcon /> })}
        {BlockButton({
          format: 'block-quote',
          children: <FormatQuoteIcon />,
        })}
        {BlockButton({
          format: 'numbered-list',
          children: <FormatListNumberedIcon />,
        })}
        {BlockButton({
          format: 'bulleted-list',
          children: <FormatListBulletedIcon />,
        })}
      </ToggleButtonGroup>
    </Grid>
  );
};

export default SlateToolbar;
