import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { login, isLoginLoading, isLoginError, selectUser } from '../redux/auth/auth.slice';
import { closeDialog } from '../redux/app/app.slice';

import { Grid, TextField, Alert, Button } from '@mui/material';

import './form.styles.scss';

const Login = (props) => {
  const { t } = useTranslation(['forms', 'translation']);
  const size = 'medium';

  const [save, setSave] = useState(false);
  const isLoading = useSelector(isLoginLoading);
  const isError = useSelector(isLoginError);
  const user = useSelector(selectUser);

  const dispatch = useDispatch();

  const handleSubmit = (form) => {
    if (isLoading) {
      return;
    }
    setSave(true);
    dispatch(login(form));
  };

  useEffect(() => {
    if (save && user) {
      dispatch(closeDialog());
    }
  }, [dispatch, save, user]);

  const validationSchema = yup.object({
    username: yup.string('').required(t('required')),
    password: yup.string('').required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          size={size}
          id="username"
          name="username"
          label={t('username')}
          value={formik.values.username}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.username && Boolean(formik.errors.username)}
          helperText={formik.touched.username && formik.errors.username}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          size={size}
          id="password"
          name="password"
          type="password"
          label={t('password')}
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
      </Grid>
      {isError ? (
        <Grid item xs={12}>
          <Alert severity="error">{t('translation:loginFailedCheckUsernameAndPassword')}</Alert>
        </Grid>
      ) : null}

      <Grid item xs={12} className="hc-form-buttons-area-right">
        <Button
          onClick={formik.submitForm}
          style={{ marginRight: 8 }}
          type="submit"
          color="primary"
          variant="contained"
        >
          {t('translation:login')}
        </Button>
        <Button variant="outlined" onClick={props.closeDialog}>
          {t('translation:cancel')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default Login;
