import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { map, mergeMap, takeUntil, catchError, filter, take } from 'rxjs/operators';

import urls, { getData } from '../../utils/static';

import {
  loadShoppingLists,
  shoppingListsCancel,
  shoppingListsFullfilled,
  shoppingListsRejected,
} from './shoppinglist.slice';

const getShoppingListsEpic = (action$, state$) =>
  action$.pipe(
    ofType(loadShoppingLists.type),
    map((action$) => {
      return action$?.payload;
    }),
    mergeMap((userId) =>
      from(getData(urls.shoppingList.getWithUserId + userId)).pipe(
        map((response) => fetchFullfilled(response)),
        takeUntil(
          action$.pipe(
            filter((action) => action.type === shoppingListsCancel.type),
            take(1)
          )
        ),
        catchError((error) =>
          of({
            type: shoppingListsRejected.type,
            // payload: error.xhr.response,
            error: true,
          })
        )
      )
    )
  );

const fetchFullfilled = (payload) => {
  return {
    type: shoppingListsFullfilled.type,
    payload: payload,
  };
};

export default getShoppingListsEpic;
