import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Typography } from '@mui/material';

import RecipeItem from './recipeItem.component';

import AddRecipeToShoppingList from '../shoppingList/addRecipeShoppingList.component';

const RecipeList = ({ recipes }) => {
  const { t } = useTranslation(['recipes']);
  const [open, setOpen] = useState(false);
  const [recipe, setRecipe] = useState(null);

  const handleAddShoppingList = (recipe) => {
    setOpen(false);
    setRecipe(recipe);
    setOpen(true);
  };

  return (
    <React.Fragment>
      {recipes?.length ? (
        <Grid container style={{ margin: '2.5rem 0' }} gap={3}>
          {recipes.map((recipe, index) => (
            <Grid item xs={12} key={index}>
              <RecipeItem addShoppingList={handleAddShoppingList} recipe={recipe} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography variant="body1" component="div" gutterBottom style={{ margin: '1rem 0' }}>
          {t('noRecipes')}
        </Typography>
      )}

      {open ? <AddRecipeToShoppingList recipe={recipe} onClose={() => setOpen(false)} /> : null}
    </React.Fragment>
  );
};
export default RecipeList;
