import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { selectLoginDialogOpen, selectLoginDialogTab, closeDialog, setLoginDialogTab } from '../../redux/app/app.slice';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Login from '../../forms/login.component';
import SignUp from '../../forms/singup.component';

export default function LoginDialog() {
  const { t } = useTranslation(['translation']);

  const open = useSelector(selectLoginDialogOpen);
  const tabValue = useSelector(selectLoginDialogTab);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeDialog());
  };

  const handleChange = (event, newValue) => {
    dispatch(setLoginDialogTab(newValue));
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <TabContext value={tabValue}>
        <DialogTitle id="scroll-dialog-title">
          {' '}
          <Box sx={{ width: '100%' }}>
            <Tabs value={tabValue} onChange={handleChange}>
              <Tab label={t('login')} value="login" />
              <Tab label={t('signUp')} value="signup" />
            </Tabs>
          </Box>
        </DialogTitle>
        <DialogContent dividers={true} id="scroll-dialog-description" ref={descriptionElementRef}>
          <TabPanel value="login">
            <Login closeDialog={handleClose} />
          </TabPanel>
          <TabPanel value="signup">
            <SignUp closeDialog={handleClose} />
          </TabPanel>
        </DialogContent>
      </TabContext>
    </Dialog>
  );
}
