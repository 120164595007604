import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { map, switchMap, takeUntil, catchError, filter, take } from 'rxjs/operators';

import urls, { getData } from '../../utils/static';
import { logout, logoutCancel, logoutFullfilled, logoutRejected } from './auth.slice';

const logoutEpic = (action$) =>
  action$.pipe(
    ofType(logout.type),
    map((action$) => {
      return action$?.payload;
    }),
    switchMap((token) =>
      from(getData(urls.logout, { Authorization: `Bearer ${token}` })).pipe(
        map((response) => fetchFullfilled(response)),
        takeUntil(
          action$.pipe(
            filter((action) => action.type === logoutCancel.type),
            take(1)
          )
        ),
        catchError((error) =>
          of({
            type: logoutRejected.type,
            payload: error,
            error: true,
          })
        )
      )
    )
  );

const fetchFullfilled = (payload) => {
  return {
    type: logoutFullfilled.type,
    payload: payload,
  };
};

export default logoutEpic;
