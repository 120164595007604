import { createSlice, createSelector } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'auth',
  initialState: {
    token: null,
    user: null,
    login: {
      loading: false,
      error: false,
      refreshError: false,
    },
    userState: {
      loading: false,
      error: false,
      refreshError: false,
    },
    logout: {
      loading: false,
      error: false,
    },
    signup: {
      loading: false,
      error: false,
      userNameError: false,
    },
  },
  reducers: {
    // LOGIN
    login: (state, payload) => {
      return {
        ...state,
        login: {
          loading: true,
          error: false,
          refreshError: false,
        },
      };
    },
    loginCancel: (state) => {
      state.login.loading = false;
    },
    loginFullfilled: (state, action) => {
      return {
        ...state,
        token: action?.payload?.token,
        login: {
          ...state.login,
          loading: false,
        },
      };
    },
    loginRejected: (state) => {
      return {
        ...state,
        login: {
          ...state.login,
          loading: false,
          error: true,
        },
      };
    },

    refresh: (state) => {
      state.login.refreshError = false;
    },
    refreshFullfilled: (state, action) => {
      return {
        ...state,
        token: action?.payload?.token,
      };
    },
    refreshRejected: (state) => {
      state.login.refreshError = true;
    },

    // LOGOUT
    logout: (state, payload) => {
      return {
        ...state,
        logout: {
          ...state.logout,
          loading: true,
          error: false,
        },
      };
    },
    logoutCancel: (state) => {
      state.logout.loading = false;
    },
    logoutFullfilled: (state) => {
      return {
        ...state,
        user: null,
        token: null,
        logout: {
          ...state.logout,
          loading: false,
        },
      };
    },
    logoutRejected: (state) => {
      return {
        ...state,
        logout: {
          ...state.logout,
          loading: false,
          error: true,
        },
      };
    },

    // USER
    fetchUser: (state) => {
      return {
        ...state,
        userState: {
          ...state.userState,
          loading: true,
          error: false,
        },
      };
    },
    userCancel: (state) => {
      return {
        ...state,
        userState: {
          ...state.userState,
          loading: false,
        },
      };
    },
    userFullfilled: (state, payload) => {
      return {
        ...state,
        user: payload?.payload,
        userState: {
          ...state.userState,
          loading: false,
        },
      };
    },
    userRejected: (state) => {
      return {
        ...state,
        userState: {
          ...state.userState,
          loading: false,
          error: true,
        },
      };
    },

    // SIGNUP
    signup: (state) => {
      return {
        ...state,
        signup: {
          ...state.signup,
          loading: true,
          error: false,
          userNameError: false,
        },
      };
    },
    signupCancel: (state) => {
      return {
        ...state,
        signup: {
          ...state.signup,
          loading: false,
        },
      };
    },
    signupFullfilled: (state, action) => {
      return {
        ...state,
        token: action?.payload?.token,
        signup: {
          ...state.signup,
          loading: false,
        },
      };
    },
    signupRejected: (state, action) => {
      return {
        ...state,
        signup: {
          ...state.signup,
          loading: false,
          error: true,
          userNameError: action?.payload?.body?.name === 'UserExistsError',
        },
      };
    },
  },
});

export const {
  login,
  loginCancel,
  loginFullfilled,
  loginRejected,
  refresh,
  refreshFullfilled,
  refreshRejected,
  logout,
  logoutCancel,
  logoutFullfilled,
  logoutRejected,
  fetchUser,
  userCancel,
  userFullfilled,
  userRejected,
  signup,
  signupCancel,
  signupFullfilled,
  signupRejected,
} = slice.actions;

const getAuth = (state) => state.auth;
export const selectToken = createSelector([getAuth], (auth) => auth.token);
export const selectUser = createSelector([getAuth], (auth) => auth.user);

export const isLoginLoading = createSelector([getAuth], (auth) => auth.login.loading);
export const isLoginError = createSelector([getAuth], (auth) => auth.login.error);

export const isLogoutLoading = createSelector([getAuth], (auth) => auth.logout.loading);
export const isLogoutError = createSelector([getAuth], (auth) => auth.logout.error);

export const isUserLoading = createSelector([getAuth], (auth) => auth.userState.loading);
export const isUSerError = createSelector([getAuth], (auth) => auth.userState.error);

export const isSignupLoading = createSelector([getAuth], (auth) => auth.signup.loading);
export const isSignupError = createSelector([getAuth], (auth) => auth.signup.error);
export const isSignupUserNameError = createSelector([getAuth], (auth) => auth.signup.userNameError);

export default slice.reducer;
