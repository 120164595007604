import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact } from 'slate-react';

import { ElementView, Leaf } from './editor.utils';

const TextEditorView = ({ initValue, textContainerStyle, placeholder }) => {
  const [value, setValue] = useState(null);
  useEffect(() => {
    try {
      const parsedValue = JSON.parse(initValue);
      setValue(parsedValue);
    } catch (error) {}
  }, [initValue]);

  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  const renderElement = useCallback((props) => <ElementView {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  return (
    <React.Fragment>
      {value ? (
        <Slate editor={editor} value={value} onChange={(value) => setValue(value)}>
          <Editable
            readOnly
            renderElement={renderElement}
            placeholder={placeholder}
            renderLeaf={renderLeaf}
            style={{ ...textContainerStyle }}
          />
        </Slate>
      ) : null}
    </React.Fragment>
  );
};

export default TextEditorView;
