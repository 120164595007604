import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Grid, IconButton, Typography, Tooltip, Button, Alert } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import ClearIcon from '@mui/icons-material/Clear';

import urls, { postData, putData } from '../../utils/static';
import {
  selectActive,
  selectIngrediants,
  isDraft,
  createDraftListItem,
  deleteDraftListItem,
  setDraftState,
  updateShoppingList,
  addShoppingList,
  setActiveShoppingList,
} from '../../redux/shoppinglist/shoppinglist.slice';
import { selectUser, selectToken } from '../../redux/auth/auth.slice';
import ListIngrediants from '../../components/shoppingList/listIngrediants.component';

const ActiveListItem = () => {
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const activeList = useSelector(selectActive);
  const ingrediants = useSelector(selectIngrediants);
  const isDraftList = useSelector(isDraft);
  const user = useSelector(selectUser);
  const token = useSelector(selectToken);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [filter, setFilter] = useState('all');
  const [filteredIngrediants, setFilteredIngrediants] = useState([]);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

  useEffect(() => {
    if (filter === 'all') {
      setFilteredIngrediants(ingrediants);
    } else if (filter === 'selected') {
      const ar = ingrediants.filter((ing) => ing.checked);
      setFilteredIngrediants(ar);
    } else if (filter === 'unselected') {
      const ar = ingrediants.filter((ing) => !ing.checked);
      setFilteredIngrediants(ar);
    }
  }, [filter, ingrediants]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleActiveItemEdit = () => {
    navigate(`edit`);
  };

  const removeSelectedList = () => {
    dispatch(deleteDraftListItem());
    setRemoveDialogOpen(false);
  };

  const handleDialogClose = () => {
    setRemoveDialogOpen(false);
  };

  const handleSave = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    setError(false);

    try {
      const dto = {
        name: activeList.name,
        ingrediants: activeList.ingrediants,
      };
      if (activeList.shoppingList && user._id === activeList.shoppingList.creator._creatorId) {
        // edit
        const list = await putData(urls.shoppingList.edit + activeList.shoppingList._id, dto, {
          Authorization: `Bearer ${token}`,
        });
        setLoading(false);
        dispatch(setDraftState(false));
        dispatch(updateShoppingList(list));
      } else {
        const list = await postData(urls.shoppingList.add, dto, { Authorization: `Bearer ${token}` });
        setLoading(false);
        dispatch(addShoppingList(list));
        dispatch(setActiveShoppingList(list));
      }
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const getSelectedIngrediantsAmount = () => {
    return ingrediants.filter((ing) => ing.checked).length;
  };

  return (
    <Grid container gap={2}>
      {activeList.isActiveShoppingList ? (
        <React.Fragment>
          <Grid container item xs={12} style={{ marginTop: '2rem' }}>
            <Grid item container gap={2} sx={{ flex: 1, alignItems: 'center' }}>
              {activeList.name ? (
                <Typography variant="h4" component="div" gutterBottom>
                  {activeList.name}
                </Typography>
              ) : null}
              {activeList.draft ? (
                <Typography variant="subtitle1" component="div" gutterBottom>
                  ({t('draft')})
                </Typography>
              ) : null}
            </Grid>
            <Grid item sx={{ alignSelf: 'end', marginBottom: 1.5 }}>
              <Tooltip title={t('edit')}>
                <IconButton onClick={handleActiveItemEdit}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('removeSelectedList')}>
                <IconButton onClick={() => setRemoveDialogOpen(true)}>
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container item xs={12} gap={2} alignItems="center">
            <RadioGroup
              row
              aria-label="filters"
              value={filter}
              onChange={handleFilterChange}
              name="filter-radio-buttons-group"
            >
              <FormControlLabel value="all" control={<Radio />} label={t('all')} />
              <FormControlLabel value="selected" control={<Radio />} label={t('selected')} />
              <FormControlLabel value="unselected" control={<Radio />} label={t('unselected')} />
            </RadioGroup>

            <Typography variant="body2">{`${getSelectedIngrediantsAmount()} / ${ingrediants.length}`}</Typography>
          </Grid>
          <Grid container item xs={12}>
            <ListIngrediants filter={filter} ingrediants={filteredIngrediants} />
          </Grid>
          {isDraftList && user ? (
            <React.Fragment>
              {error ? (
                <Grid item xs={12}>
                  <Alert severity="error">{t('shoppingListSaveFailed')}</Alert>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <Button variant="contained" size="small" onClick={handleSave}>
                  {t('save')}
                </Button>
              </Grid>
            </React.Fragment>
          ) : null}
        </React.Fragment>
      ) : (
        <NoActiveListItem />
      )}

      <Dialog open={removeDialogOpen} maxWidth="sm" fullWidth>
        <DialogTitle id="alert-dialog-title">{t('removeSelectedList')}</DialogTitle>
        <DialogActions>
          <Button size="small" onClick={removeSelectedList} variant="contained">
            {t('remove')}
          </Button>
          <Button size="small" onClick={handleDialogClose}>
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
export default ActiveListItem;

const NoActiveListItem = () => {
  const { t } = useTranslation(['translation']);
  const dispatch = useDispatch();

  const createNewList = () => {
    dispatch(createDraftListItem());
  };

  return (
    <Grid container item xs={12}>
      <Grid item sx={{ flex: 1 }}>
        <Typography variant="h4" component="div" gutterBottom style={{ marginTop: '2rem' }}>
          {t('noActiveList')}
        </Typography>
      </Grid>
      <Grid item sx={{ alignSelf: 'end', marginBottom: 1.5 }}>
        <Tooltip title={t('createNewList')}>
          <IconButton onClick={createNewList}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};
