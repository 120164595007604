import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Grid, TextField } from '@mui/material';

import { getRecipes, setSearchName, selectSearchName, getQueryParams } from '../../redux/recipe/recipes.slice';

const RecipeSearch = () => {
  const { t } = useTranslation(['translation']);
  const dispatch = useDispatch();
  const recipeName = useSelector(selectSearchName);

  const handleRecipeNameChange = (event) => {
    dispatch(setSearchName(event.target.value));
  };

  const searchRecipes = () => {
    const queryParams = getQueryParams(recipeName);
    dispatch(getRecipes(queryParams));
  };

  return (
    <Grid container>
      <Grid item container xs={12} gap={2} sx={{ marginTop: 1, alignItems: 'center' }}>
        <div style={{ flex: 1 }}>
          <TextField
            fullWidth
            defaultValue={recipeName}
            label={t('searchRecipes')}
            id="recipe-search-field"
            onChange={handleRecipeNameChange}
          />
        </div>

        <Button variant="contained" onClick={searchRecipes}>
          {t('search')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default RecipeSearch;
