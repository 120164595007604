import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import getTheme from './utils/theme';

import { fetchUser, selectUser, isUserLoading, selectToken, refresh } from './redux/auth/auth.slice';
import { selectDarkTheme } from './redux/settings/settings.slice';
import Paper from '@mui/material/Paper';
import { SnackbarProvider } from 'notistack';

import Header from './framework/header/header.component';
import Main from './framework/main/main.component';

import './App.scss';

function App() {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const userLoading = useSelector(isUserLoading);
  const darkTheme = useSelector(selectDarkTheme);
  const [theme, setTheme] = useState(getTheme(darkTheme));

  useEffect(() => {
    setTheme(getTheme(darkTheme));
  }, [darkTheme]);

  const verifyUser = useCallback(() => {
    dispatch(refresh());

    // call refreshToken every 5 minutes to renew the authentication token.
    setTimeout(verifyUser, 5 * 60 * 1000);
  }, [dispatch]);

  useEffect(() => {
    verifyUser();
  }, [verifyUser]);

  useEffect(() => {
    if (token && !user && !userLoading) {
      dispatch(fetchUser(token));
    }
  }, [dispatch, token, user, userLoading]);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3} dense={false} autoHideDuration={3000}>
        <Paper elevation={0} style={{ minHeight: '100vh', borderRadius: '0' }}>
          <Header />
          <Main />
        </Paper>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
