import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import urls, { postData } from '../../utils/static';

import { selectToken } from '../../redux/auth/auth.slice';
import { addRecipe } from '../../redux/myRecipe/myRecipe.slice';

import RecipeForm from '../../forms/recipeForm.component';

const CreateRecipe = () => {
  const { t } = useTranslation(['translation', 'recipes']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const [errorText, setErrorText] = useState('');
  const [loading, setLoading] = useState(false);

  const createRecipe = async (values) => {
    if (loading) {
      return;
    }
    setLoading(true);
    setErrorText('');

    try {
      const res = await postData(urls.recipes.add, values, { Authorization: `Bearer ${token}` });
      setLoading(false);
      dispatch(addRecipe(res.newRecipe));
      navigate(`/recipes/${res.newRecipe._id}`, { state: res.newRecipe });
    } catch (error) {
      setLoading(false);
      if (error.status === 401) {
        setErrorText(t('translation:error401'));
      } else {
        setErrorText(t('recipes:recipeSaveFailed'));
      }
    }
  };

  const cancelCreating = () => {
    navigate(-1);
  };

  return <RecipeForm onSave={createRecipe} onCancel={cancelCreating} loading={loading} errorText={errorText} />;
};
export default CreateRecipe;
