import { createSlice, createSelector, current } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'shoppinglists',
  initialState: {
    myShoppingLists: [],
    listState: {
      loading: false,
      error: false,
      loaded: false,
    },
    active: {
      ingrediants: [],
      name: '',
      selectedRecipes: [],
      isActiveShoppingList: false,

      shoppingList: null, // if selected shoppinglist
      draft: false,
      lastEditedTime: new Date().toISOString(),
    },
  },
  reducers: {
    loadShoppingLists: (state, payload) => {
      return {
        ...state,
        listState: {
          ...state.listState,
          loading: true,
          error: false,
        },
      };
    },
    shoppingListsCancel: (state) => {
      state.listState.loading = false;
      state.listState.loaded = true;
    },
    shoppingListsFullfilled: (state, action) => {
      return {
        ...state,
        myShoppingLists: action.payload,
        listState: {
          loading: false,
          error: false,
          loaded: true,
        },
      };
    },
    shoppingListsRejected: (state) => {
      return {
        ...state,
        listState: {
          loading: false,
          error: true,
          loaded: true,
        },
      };
    },
    addShoppingList: (state, action) => {
      return {
        ...state,
        myShoppingLists: [action.payload, ...state.myShoppingLists],
      };
    },
    updateShoppingList: (state, action) => {
      const myRecipes = state.myShoppingLists.map((l) => {
        if (l._id === action.payload._id) {
          l = action.payload;
        }
        return l;
      });

      return {
        ...state,
        myShoppingLists: myRecipes,
      };
    },

    createDraftListItem: (state, action) => {
      let ing = action?.payload?.ingrediants ?? [];
      ing = ing.slice().sort(sortIngrediant);

      ing = ing.map((ing) => {
        return { ...ing, checked: false };
      });

      return {
        ...state,
        active: {
          ...state.active,
          name: '',
          ingrediants: ing,
          selectedRecipes: action ? [action.payload] : [],
          isActiveShoppingList: true,

          shoppingList: null,
          draft: true,
          lastEditedTime: new Date().toISOString(),
        },
      };
    },
    setActiveShoppingList: (state, action) => {
      return {
        ...state,
        active: {
          ...state.active,
          name: action.payload.name,
          ingrediants: addIngrediants([], action.payload.ingrediants),
          selectedRecipes: [],

          shoppingList: action.payload,
          isActiveShoppingList: true,
          draft: false,
          lastEditedTime: new Date().toISOString(),
        },
      };
    },
    addRecipeActiveItem: (state, action) => {
      return {
        ...state,
        active: {
          ...state.active,
          ingrediants: addIngrediants(current(state.active.ingrediants), action.payload.ingrediants),
          selectedRecipes: [...state.active.selectedRecipes, action.payload],

          draft: true,
          lastEditedTime: new Date().toISOString(),
        },
      };
    },
    setActiveIngrediants: (state, action) => {
      return {
        ...state,
        active: {
          ...state.active,
          name: action.payload.name,
          ingrediants: addIngrediants([], action.payload.ingrediants),

          draft: true,
          lastEditedTime: new Date().toISOString(),
        },
      };
    },
    toggleChecked: (state, action) => {
      const ing = state.active.ingrediants.map((inge) => {
        if (JSON.stringify(action.payload) === JSON.stringify(inge)) {
          return { ...inge, checked: !inge.checked };
        }
        return { ...inge };
      });

      return {
        ...state,
        active: {
          ...state.active,
          ingrediants: [...ing],
          lastEditedTime: new Date().toISOString(),
        },
      };
    },
    deleteDraftListItem: (state) => {
      return {
        ...state,
        active: {
          ingrediants: [],
          name: '',
          selectedRecipes: [],
          isActiveShoppingList: false,

          draft: false,
          lastEditedTime: new Date().toISOString(),
        },
      };
    },
    setDraftState: (state, action) => {
      state.active.draft = action.payload;
    },
  },
});

export const {
  loadShoppingLists,
  shoppingListsCancel,
  shoppingListsFullfilled,
  shoppingListsRejected,
  addShoppingList,
  updateShoppingList,

  createDraftListItem,
  addRecipeActiveItem,
  setActiveShoppingList,
  setActiveIngrediants,
  toggleChecked,
  deleteDraftListItem,
  setDraftState,
} = slice.actions;

const getLists = (state) => state.shoppinglists;

export const selectShoppingLists = createSelector([getLists], (lists) => lists.myShoppingLists);
export const isLoaded = createSelector([getLists], (lists) => lists.listState.loaded);
export const isLoading = createSelector([getLists], (lists) => lists.listState.loading);
export const isError = createSelector([getLists], (lists) => lists.listState.error);

export const isActiveShoppingList = createSelector([getLists], (lists) => lists.active.isActiveShoppingList);
export const isDraft = createSelector([getLists], (lists) => lists.active.draft);
export const selectActive = createSelector([getLists], (lists) => lists.active);
export const selectIngrediants = createSelector([getLists], (lists) => lists.active.ingrediants);

export default slice.reducer;

const addIngrediants = (ingrediants, newIngrediants) => {
  console.log(ingrediants);
  console.log(newIngrediants);
  const newIng = newIngrediants.map((ing) => {
    return { ...ing, checked: false };
  });
  const ar = ingrediants.concat(newIng);

  ar.sort(sortIngrediant);

  return ar.reduce((result, current) => {
    const index = result.findIndex((ing) => ing.name === current.name && ing.unit === current.unit);
    if (index !== -1) {
      const oldAmount = result[result.length - 1].amount;
      const amount = current.amount;

      if (typeof amount === 'number' && typeof oldAmount === 'number') {
        result[result.length - 1] = { ...result[result.length - 1], amount: amount + oldAmount };
      } else if (typeof amount === 'number') {
        result[result.length - 1] = { ...result[result.length - 1], amount: amount };
      }
    } else {
      result.push(current);
    }
    return result;
  }, []);
};

const sortIngrediant = (a, b) => {
  var nameA = a.name.toUpperCase().trim();
  var nameB = b.name.toUpperCase().trim();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  return 0;
};
