import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Alert, Grid, Typography, Pagination } from '@mui/material';

import RecipeSearch from '../../components/recipeSearch/recipeSearch.component';
import RecipeList from '../../components/recipeList/recipeList.component';
import Spinner from '../../components/spinner/spinner.component';

import {
  selectRecipes,
  isLoading,
  isError,
  selectTotalItems,
  selectPerPage,
  selectPage,
  getRecipesPagination,
  isInitialLoaded,
} from '../../redux/recipe/recipes.slice';

const Home = () => {
  const { t } = useTranslation(['translation', 'recipes']);
  const dispatch = useDispatch();

  const recipes = useSelector(selectRecipes);
  const error = useSelector(isError);
  const loading = useSelector(isLoading);
  const initialLoaded = useSelector(isInitialLoaded);

  const page = useSelector(selectPage);
  const perPage = useSelector(selectPerPage);
  const totalItems = useSelector(selectTotalItems);

  const paginationChange = (event, value) => {
    dispatch(getRecipesPagination(value));
  };

  useEffect(() => {
    if (initialLoaded === false) {
      dispatch(getRecipesPagination(page));
    }
  }, [initialLoaded, page, dispatch]);

  return (
    <Grid container>
      <Grid item sx={{ marginTop: 8 }} xs={12}>
        <Typography variant="h3" align="center" gutterBottom>
          {t('handlessCook')}
        </Typography>
      </Grid>
      <Grid item container xs={12}>
        <RecipeSearch />
      </Grid>
      {error ? <Alert severity="error">{t('recipes:recipeLoadFailed')}</Alert> : null}
      <Grid item container xs={12}>
        {loading ? <Spinner /> : <RecipeList recipes={recipes} />}
      </Grid>
      {perPage && totalItems && Math.ceil(totalItems / perPage) > 1 ? (
        <Grid item xs={12} sx={{ marginBottom: 4 }}>
          <Pagination
            count={Math.ceil(totalItems / perPage)}
            page={page}
            variant="outlined"
            color="secondary"
            onChange={paginationChange}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};
export default Home;
