import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Grid, Typography, Button } from '@mui/material';

import Spinner from '../../components/spinner/spinner.component';
import RecipeList from '../../components/recipeList/recipeList.component';

import { get, selectMyRecipes, isMyRecipesLoaded } from '../../redux/myRecipe/myRecipe.slice';
import { selectUser } from '../../redux/auth/auth.slice';

const MyRecipes = () => {
  const { t } = useTranslation(['recipes']);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const recipesLoaded = useSelector(isMyRecipesLoaded);
  const recipes = useSelector(selectMyRecipes);

  useEffect(() => {
    if (user && !recipesLoaded) {
      dispatch(get(user._id));
    }
  }, [user, dispatch, recipesLoaded]);

  const createNewRecipe = () => {
    navigate('/recipes/new');
  };

  return (
    <Grid container>
      <Grid container item xs={12}>
        <Grid item sx={{ flex: 1 }}>
          <Typography variant="h4" component="div" gutterBottom style={{ marginTop: '2rem' }}>
            {t('myRecipes')}
          </Typography>
        </Grid>

        <Grid item sx={{ alignSelf: 'end', marginBottom: 1.5 }}>
          <Button variant="contained" color="primary" onClick={createNewRecipe}>
            {t('newRecipe')}
          </Button>
        </Grid>
      </Grid>

      {recipesLoaded ? (
        <Grid item xs={12}>
          <RecipeList recipes={recipes} />
        </Grid>
      ) : (
        <Spinner />
      )}
    </Grid>
  );
};
export default MyRecipes;
