import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { map, switchMap, takeUntil, catchError, filter, take } from 'rxjs/operators';

import urls, { getData } from '../../utils/static';
import { getRecipes, getRecipesPagination, recipesCancel, recipesFullfilled, recipesRejected } from './recipes.slice';

const getRecipesEpic = (action$, state$) =>
  action$.pipe(
    ofType(getRecipes.type),
    map((action$) => {
      return action$.payload;
    }),
    switchMap((query) =>
      from(getData(urls.recipes.get + query)).pipe(
        map((response) => fetchFullfilled(response)),
        takeUntil(
          action$.pipe(
            filter((action) => action.type === recipesCancel.type),
            take(1)
          )
        ),
        catchError((error) =>
          of({
            type: recipesRejected.type,
            error: true,
          })
        )
      )
    )
  );

export const getRecipesPaginationEpic = (action$, state$) =>
  action$.pipe(
    ofType(getRecipesPagination.type),
    map((action$) => {
      let query = state$.value.recipes.urlQueryParams;
      query = query ? query + `&page=${action$.payload}` : `?page=${action$.payload}`;
      return query;
    }),
    switchMap((query) =>
      from(getData(urls.recipes.get + query)).pipe(
        map((response) => fetchFullfilled(response)),
        takeUntil(
          action$.pipe(
            filter((action) => action.type === recipesCancel.type),
            take(1)
          )
        ),
        catchError((error) =>
          of({
            type: recipesRejected.type,
            error: true,
          })
        )
      )
    )
  );

const fetchFullfilled = (payload) => {
  return {
    type: recipesFullfilled.type,
    payload: payload,
  };
};

export default getRecipesEpic;
