import React, { useState } from 'react';
// import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../i18n';
// import { useNavigate } from 'react-router-dom';

// import EditIcon from '@mui/icons-material/Edit';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton, Collapse, CardActions, CardContent, CardHeader, Card, Tooltip } from '@mui/material';

// import { selectUser } from '../../redux/auth/auth.slice';
import IngrediantsList from '../recipeList/ingrediantsList.component';
import UseShoppingListDialog from './useShoppingListDialog.component';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function ShoppingListItem({ shoppinglist, addShoppingList }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card raised sx={{ maxWidth: '100%' }}>
      <CardHeader
        action={<ShoppingListMenuItem shoppinglist={shoppinglist} />}
        title={shoppinglist.name}
        subheader={formatDate(new Date(shoppinglist.createdAt))}
      />

      {shoppinglist?.ingrediants?.length ? (
        <CardActions disableSpacing>
          <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
      ) : null}

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <IngrediantsList ingrediants={shoppinglist.ingrediants} />
        </CardContent>
      </Collapse>
    </Card>
  );
}

const ShoppingListMenuItem = ({ shoppinglist }) => {
  // const navigate = useNavigate();
  const { t } = useTranslation(['translation']);
  // const user = useSelector(selectUser);
  const [open, setOpen] = useState(false);

  const handleUseShoppingList = () => {
    setOpen(true);
  };

  // const handleShoppingListView = () => {};
  // const handleShoppingListEdit = () => {};

  return (
    <React.Fragment>
      <Tooltip title={t('useThisShoppingList')}>
        <IconButton onClick={handleUseShoppingList}>
          <PlaylistAddCheckIcon />
        </IconButton>
      </Tooltip>
      {/* <Tooltip title={t('view')}>
        <IconButton onClick={handleShoppingListView}>
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
      {user?._id === shoppinglist.creator._creatorId ? (
        <Tooltip title={t('edit')}>
          <IconButton onClick={handleShoppingListEdit}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      ) : null} */}

      {open ? <UseShoppingListDialog shoppinglist={shoppinglist} onClose={() => setOpen(false)} /> : null}
    </React.Fragment>
  );
};
