const urls = {
  login: process.env.REACT_APP_API + '/api/users/login',
  signup: process.env.REACT_APP_API + '/api/users/signup',
  refresh: process.env.REACT_APP_API + '/api/users/refreshToken',
  logout: process.env.REACT_APP_API + '/api/users/logout',
  me: process.env.REACT_APP_API + '/api/users/me',

  recipes: {
    add: process.env.REACT_APP_API + '/api/recipes/add',
    get: process.env.REACT_APP_API + '/api/recipes/recipes', // queryParams name,
    getWithUserId: process.env.REACT_APP_API + '/api/recipes/recipes/', // userId
    getRecipe: process.env.REACT_APP_API + '/api/recipes/recipe/', // recipeId
    editRecipe: process.env.REACT_APP_API + '/api/recipes/recipe/', // recipeId
    deleteRecipe: process.env.REACT_APP_API + '/api/recipes/recipe/', // recipeId
  },

  shoppingList: {
    add: process.env.REACT_APP_API + '/api/shoppinglist/add',
    edit: process.env.REACT_APP_API + '/api/shoppinglist/edit/', // shoppingListId
    getWithUserId: process.env.REACT_APP_API + '/api/shoppinglist/shoppinglists/', // userId
  },
};

export default urls;

export const postData = async (url, data, headers) => {
  const response = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    body: JSON.stringify(data),
  });
  if (response.ok) {
    return response.json();
  } else {
    return handleError(response);
  }
};

export const putData = async (url, data, headers) => {
  const response = await fetch(url, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    body: JSON.stringify(data),
  });
  if (response.ok) {
    return response.json();
  } else {
    return handleError(response);
  }
};

export const getData = async (url, headers) => {
  const response = await fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  });
  if (response.ok) {
    return response.json();
  } else {
    return handleError(response);
  }
};

export const deleteData = async (url, headers) => {
  const response = await fetch(url, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  });
  if (response.ok) {
    return response.json();
  } else {
    return handleError(response);
  }
};

const handleError = async (res) => {
  const responseError = {
    statusText: res.statusText || 'Something went wrong',
    status: res.status || '',
  };

  try {
    const body = await res.json();
    responseError.body = body;
    return Promise.reject(responseError);
  } catch (error) {
    return Promise.reject(responseError);
  }
};
