import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';

const Spinner = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', margin: '3rem' }}>
      <CircularProgress disableShrink />
    </div>
  );
};
export default Spinner;
