import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Grid, Button, Typography } from '@mui/material';

import ShoppingListItem from '../../components/shoppingList/shoppinglistItem.component';

import Spinner from '../../components/spinner/spinner.component';
import { selectUser } from '../../redux/auth/auth.slice';
import {
  loadShoppingLists,
  selectShoppingLists,
  isLoaded,
  isLoading,
} from '../../redux/shoppinglist/shoppinglist.slice';

const MyShoppingList = () => {
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const listsLoaded = useSelector(isLoaded);
  const loading = useSelector(isLoading);
  const myShoppingLists = useSelector(selectShoppingLists);

  useEffect(() => {
    if (!listsLoaded && user && !loading) {
      dispatch(loadShoppingLists(user._id));
    }
  }, [user, dispatch, listsLoaded, loading]);

  const createShoppingList = () => {
    navigate('/shoppinglist/new');
  };

  return (
    <React.Fragment>
      <Grid container gap={2} sx={{ marginBottom: '1.5rem' }}>
        <Grid container item xs={12}>
          <Grid item sx={{ flex: 1 }}>
            <Typography variant="h4" component="div" gutterBottom style={{ marginTop: '2rem' }}>
              {t('myShoppingLists')}
            </Typography>
          </Grid>

          <Grid item sx={{ alignSelf: 'end', marginBottom: 1.5 }}>
            <Button variant="contained" color="primary" onClick={createShoppingList}>
              {t('newShoppingList')}
            </Button>
          </Grid>
        </Grid>

        {!loading ? (
          <Grid container item gap={2}>
            {myShoppingLists.map((list) => (
              <Grid key={list._id} item xs={12}>
                <ShoppingListItem shoppinglist={list} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Spinner />
        )}
      </Grid>
    </React.Fragment>
  );
};
export default MyShoppingList;
