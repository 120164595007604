import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n, { languages, fallbackLng } from '../../../i18n';

import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import AddIcon from '@mui/icons-material/Add';
import Divider from '@mui/material/Divider';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import Switch from '@mui/material/Switch';
import TranslateIcon from '@mui/icons-material/Translate';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { openDialog } from '../../../redux/app/app.slice';
import { selectUser, selectToken, logout as logoutRedux } from '../../../redux/auth/auth.slice';
import { toggleTheme, selectDarkTheme } from '../../../redux/settings/settings.slice';

const UserMenu = (props) => {
  const { t } = useTranslation(['translation']);
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const darkTheme = useSelector(selectDarkTheme);
  const dispatch = useDispatch();
  const currlng = i18n.language;
  const [curLang, setCurLang] = useState(i18n.language);

  useEffect(() => {
    if (!currlng) {
      return;
    }

    const found = languages.find((lng) => lng.key === currlng);

    if (!found) {
      i18n.changeLanguage(fallbackLng);
    }
  }, [currlng]);

  i18n.on('languageChanged', (lng) => {
    setCurLang(lng);
  });

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  const openLoginDialog = () => {
    dispatch(openDialog('login'));
    props.closeMenu();
  };

  const openSignUpDialog = () => {
    dispatch(openDialog('signup'));
    props.closeMenu();
  };

  const logout = () => {
    dispatch(logoutRedux(token));
    props.closeMenu();
  };

  const handleThemeChange = () => {
    dispatch(toggleTheme());
  };

  return (
    <React.Fragment>
      {!user ? (
        <React.Fragment>
          {' '}
          <MenuItem onClick={openLoginDialog}>
            <ListItemIcon>
              <LoginIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t('login')}</ListItemText>
          </MenuItem>
          <MenuItem onClick={openSignUpDialog}>
            <ListItemIcon>
              <AddIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t('signUp')}</ListItemText>
          </MenuItem>
          <Divider />
        </React.Fragment>
      ) : null}
      <MenuItem onClick={handleThemeChange}>
        <ListItemIcon>
          <DarkModeIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('darkMode')}</ListItemText>
        <Switch checked={darkTheme} />
      </MenuItem>
      <MenuItem>
        <ListItemIcon>
          <TranslateIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('language')}</ListItemText>
        <Box sx={{ minWidth: 60, marginLeft: 4 }}>
          <FormControl fullWidth>
            <Select
              labelId="select-language-label"
              id="select-language"
              value={curLang}
              label={t('language')}
              onChange={changeLanguage}
              autoWidth={true}
              variant="standard"
            >
              {languages.map((lang) => (
                <MenuItem key={lang.key} value={lang.key}>
                  {' '}
                  {t(lang.name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </MenuItem>
      {user ? (
        <React.Fragment>
          <Divider />
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t('logout')}</ListItemText>
          </MenuItem>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};
export default UserMenu;
