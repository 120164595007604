import React from 'react';
// import { useTranslation } from 'react-i18next';
import { formatDate } from '../../i18n';

// import { styled } from '@mui/material/styles';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// import CardMedia from '@mui/material/CardMedia';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import ShareIcon from '@mui/icons-material/Share';
// import RestaurantIcon from '@mui/icons-material/Restaurant';
// import TimelapseIcon from '@mui/icons-material/Timelapse';

import { red } from '@mui/material/colors';
import {
  Grid,
  Chip,
  // IconButton,
  Typography,
  Avatar,
  // Collapse,
  // CardActions,
  CardContent,
  CardHeader,
  Card,
  Tooltip,
} from '@mui/material';

import RecipeMenuItem from './recipeItemMenu.component';

// const ExpandMore = styled((props) => {
//   const { expand, ...other } = props;
//   return <IconButton {...other} />;
// })(({ theme, expand }) => ({
//   transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
//   marginLeft: 'auto',
//   transition: theme.transitions.create('transform', {
//     duration: theme.transitions.duration.shortest,
//   }),
// }));

export default function RecipeItem({ recipe, addShoppingList }) {
  // const { t } = useTranslation(['recipes']);
  // const [expanded, setExpanded] = React.useState(false);

  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };

  return (
    <Card raised sx={{ maxWidth: '100%' }}>
      <CardHeader
        avatar={
          <Tooltip title={recipe.creator.name}>
            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe-creator">
              {recipe.creator.name.substring(0, 2).toUpperCase()}
            </Avatar>
          </Tooltip>
        }
        action={<RecipeMenuItem addShoppingList={addShoppingList} recipe={recipe} />}
        title={recipe.name}
        subheader={formatDate(new Date(recipe.createdAt))}
      />
      {/* <CardMedia component="img" height="194" image="/static/images/cards/paella.jpg" alt="Paella dish" /> */}

      <CardContent>
        {recipe.tags.length ? (
          <Grid container gap={1} style={{ marginBottom: '5px' }}>
            {recipe.tags.map((tag) => (
              <Chip key={tag} label={tag} size="small" />
            ))}
          </Grid>
        ) : null}

        <Typography style={{ whiteSpace: 'break-spaces' }} variant="body2" color="text.secondary">
          {recipe.description.trim()}
        </Typography>
      </CardContent>

      {/* <CardActions disableSpacing>
        <RestaurantIcon color="action" />

        <TimelapseIcon color="action" /> */}

      {/* <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
          <ExpandMoreIcon />
        </ExpandMore> */}
      {/* </CardActions> */}

      {/*<Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {t('method')}
          </Typography>
          <Typography style={{ whiteSpace: 'break-spaces' }} paragraph>
            {recipe.instructionAndNotes.trim()}
          </Typography>
        </CardContent>
      </Collapse> */}
    </Card>
  );
}
