import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/auth/auth.slice';
import logo from '../../logo.png';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import PersonIcon from '@mui/icons-material/Person';

import useWindowSize from '../../utils/hook';

import UserMenu from './userMenu/userMenu.component';

import './header.styles.scss';

const homePage = {
  name: 'home',
  route: '/',
};
const lPages = [
  {
    name: 'shoppingList',
    tooltip: 'goToShoppingList',
    route: '/shoppinglist/active',
  },
];
const authPages = [
  {
    name: 'myRecipes',
    route: '/recipes/me',
  },
  {
    name: 'myShoppingLists',
    route: '/shoppinglist/me',
  },
  ...lPages,
];

const Header = () => {
  const { t } = useTranslation(['translation']);
  const { width } = useWindowSize();
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [preWidth, setPreWidth] = React.useState(null);
  const [pages, setPages] = React.useState(lPages);

  useEffect(() => {
    if (user) {
      setPages(authPages);
    } else {
      setPages(lPages);
    }
  }, [user]);

  useEffect(() => {
    if (preWidth !== width && anchorElNav) {
      setAnchorElNav(null);
    }
    setPreWidth(width);
  }, [width, preWidth, anchorElNav]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const pageChange = (event, value) => {
    if (value?.route) {
      navigate(value.route);
    }
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* small pages */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar-dropdown"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar-dropdown"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.name}
                  onClick={() => {
                    handleCloseNavMenu();
                    pageChange(null, page);
                  }}
                >
                  <Typography textAlign="center">{t(`${page.name}`)}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {/* Logo */}
          <Box sx={{ flexGrow: { xs: '1', md: '0' } }}>
            <Tooltip title={t(`${homePage.name}`)}>
              <IconButton onClick={() => pageChange(null, homePage)} sx={{ p: 0 }}>
                <Avatar src={logo} alt="logo" />
              </IconButton>
            </Tooltip>
          </Box>

          {/* medium pages */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <nav>
              <Tabs
                value={false}
                onChange={pageChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tab pages"
              >
                {pages.map((page) => (
                  <Tooltip key={page.name} title={t(page.tooltip)}>
                    <Tab label={t(`${page.name}`)} value={page} />
                  </Tooltip>
                ))}
              </Tabs>
            </nav>
          </Box>

          {/* user settings */}
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={t('openSettings')}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar>{user ? user.username.charAt(0).toUpperCase() : <PersonIcon />}</Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <UserMenu closeMenu={handleCloseUserMenu} />
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;
