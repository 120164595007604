import React from 'react';
import { useDispatch } from 'react-redux';

import { Grid, Checkbox, ListItemButton, ListItemText, List, Link, Tooltip } from '@mui/material';

import { toggleChecked } from '../../redux/shoppinglist/shoppinglist.slice';
import { useTranslation } from 'react-i18next';

export default function ListIngrediants({ ingrediants, filter }) {
  return (
    <List
      sx={{
        width: '100%',
      }}
    >
      {ingrediants.map((ing, i) => (
        <ListIngrediantsItem filter={filter} key={i} i={i} ingrediant={ing} />
      ))}
    </List>
  );
}

const ListIngrediantsItem = ({ ingrediant, filter }) => {
  const { t } = useTranslation(['translation']);
  const dispatch = useDispatch();

  const ingToggleChecked = () => {
    dispatch(toggleChecked(ingrediant));
  };

  return (
    <ListItemButton
      style={ingrediant.checked && filter === 'all' ? { opacity: 0.4 } : null}
      divider
      onClick={ingToggleChecked}
    >
      <Grid item>
        <Checkbox
          checked={ingrediant.checked}
          style={{
            marginRight: '1rem',
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <ListItemText xs={3} primary={`${ingrediant.amount ?? ''} ${ingrediant.unit}`} />
      </Grid>
      <Grid item sx={{ flex: 1 }}>
        {ingrediant.productUrl ? (
          <Tooltip title={t('openLink')}>
            <Link
              href={ingrediant.productUrl}
              target="_blank"
              rel="noopener"
              underline="hover"
              onClick={(e) => e.stopPropagation()}
            >
              {ingrediant.name}
            </Link>
          </Tooltip>
        ) : (
          <ListItemText primary={ingrediant.name} />
        )}
      </Grid>
    </ListItemButton>
  );
};
