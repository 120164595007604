import { createSlice, createSelector } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'recipes',
  initialState: {
    searchName: '',
    urlQueryParams: '',
    page: 1,
    totalItems: 0,
    perPage: 0,
    recipes: {
      initialLoaded: false,
      recipes: [],
      loading: false,
      error: false,
    },
  },
  reducers: {
    // get
    getRecipes: (state, payload) => {
      return {
        ...state,
        page: payload ? 1 : state.page,
        urlQueryParams: payload ? payload.payload : state.urlQueryParams,
        recipes: {
          loading: true,
          error: false,
        },
      };
    },
    getRecipesPagination: (state, payload) => {
      return {
        ...state,
        page: payload.payload,
        recipes: {
          loading: true,
          error: false,
        },
      };
    },
    recipesCancel: (state) => {
      state.recipes.loading = false;
    },
    recipesFullfilled: (state, payload) => {
      return {
        ...state,
        totalItems: payload.payload.totalItems,
        perPage: payload.payload.perPage,
        recipes: {
          ...state.recipes,
          initialLoaded: true,
          recipes: payload.payload.recipes,
          loading: false,
        },
      };
    },
    recipesRejected: (state) => {
      return {
        ...state,
        recipes: {
          ...state.recipes,
          loading: false,
          error: true,
        },
      };
    },
    setSearchName: (state, payload) => {
      return {
        ...state,
        searchName: payload.payload,
      };
    },
  },
});

export const { getRecipes, getRecipesPagination, recipesCancel, recipesFullfilled, recipesRejected, setSearchName } =
  slice.actions;

const recipes = (state) => state.recipes;

export const selectRecipes = createSelector([recipes], (recipes) => recipes.recipes.recipes);
export const isLoading = createSelector([recipes], (recipes) => recipes.recipes.loading);
export const isError = createSelector([recipes], (recipes) => recipes.recipes.error);
export const isInitialLoaded = createSelector([recipes], (recipes) => recipes.recipes.initialLoaded);

export const selectSearchName = createSelector([recipes], (recipes) => recipes.searchName);

export const selectTotalItems = createSelector([recipes], (recipes) => recipes.totalItems);
export const selectPerPage = createSelector([recipes], (recipes) => recipes.perPage);
export const selectPage = createSelector([recipes], (recipes) => recipes.page);

export default slice.reducer;

export const getQueryParams = (name) => {
  let queryString = '';
  if (name) {
    queryString += `?name=${name}`;
  }
  return queryString;
};
