import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { selectToken } from '../../redux/auth/auth.slice';

import urls, { getData } from '../../utils/static';

import { Grid, Alert } from '@mui/material';

import Spinner from '../../components/spinner/spinner.component';
import RecipeEdit from '../../components/editRecipe/editRecipe.component';

const EditRecipe = () => {
  const { t } = useTranslation(['recipes', 'translation']);
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const token = useSelector(selectToken);
  const { state } = useLocation();
  const { recipeId } = useParams();

  const [recipe, setRecipe] = useState(state);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const loadRecipe = (id) => {
      if (loading) {
        return;
      }
      setLoading(true);
      setError(false);

      getData(urls.recipes.getRecipe + id)
        .then((res) => {
          setRecipe(res);
          setLoading(false);
        })
        .catch((err) => {
          setError(true);
          setLoading(false);
        });
    };

    if (!recipe && !error) {
      loadRecipe(recipeId);
    }
  }, [recipe, error, recipeId, loading]);

  return (
    <React.Fragment>
      <Grid container gap={6} style={{ marginTop: '2rem' }}>
        {error ? (
          <Alert severity="error">{t('recipeLoadFailed')}</Alert>
        ) : !recipe ? (
          <Grid item sx={{ flex: '1' }}>
            <Spinner />
          </Grid>
        ) : (
          <RecipeEdit recipe={recipe} />
        )}
      </Grid>
    </React.Fragment>
  );
};
export default EditRecipe;
