import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { map, switchMap, takeUntil, catchError, filter, take } from 'rxjs/operators';

import urls, { getData } from '../../utils/static';
import { get, getCancel, getFullfilled, getRejected } from './myRecipe.slice';

const getMyRecipesEpic = (action$) =>
  action$.pipe(
    ofType(get.type),
    map((action$) => {
      return action$?.payload;
    }),
    switchMap((userId) =>
      from(getData(urls.recipes.getWithUserId + userId)).pipe(
        map((response) => fetchFullfilled(response)),
        takeUntil(
          action$.pipe(
            filter((action) => action.type === getCancel.type),
            take(1)
          )
        ),
        catchError((error) =>
          of({
            type: getRejected.type,
            error: true,
          })
        )
      )
    )
  );

const fetchFullfilled = (payload) => {
  return {
    type: getFullfilled.type,
    payload: payload,
  };
};

export default getMyRecipesEpic;
