import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { map, switchMap, takeUntil, catchError, filter, take } from 'rxjs/operators';

import urls, { getData } from '../../utils/static';
import { fetchUser, userCancel, userFullfilled, userRejected } from './auth.slice';

const userEpic = (action$) =>
  action$.pipe(
    ofType(fetchUser.type),
    map((action$) => {
      return action$?.payload;
    }),
    switchMap((token) =>
      from(getData(urls.me, { Authorization: `Bearer ${token}` })).pipe(
        map((response) => fetchFullfilled(response)),
        takeUntil(
          action$.pipe(
            filter((action) => action.type === userCancel.type),
            take(1)
          )
        ),
        catchError((error) =>
          of({
            type: userRejected.type,
            payload: error,
            error: true,
          })
        )
      )
    )
  );

const fetchFullfilled = (payload) => ({
  type: userFullfilled.type,
  payload: payload,
});

export default userEpic;
