import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { Container } from '@mui/material';
import LoginDialog from '../../components/loginDialog/loginDialog.component';

import Home from '../../pages/home/home.component';
import MyRecipes from '../../pages/myRecipes/myRecipes.component';
import NewRecipe from '../../pages/newRecipe/newRecipe.component';
import ViewRecipe from '../../pages/recipe/viewRecipe.component';
import EditRecipe from '../../pages/recipe/editRecipe.component';

import ShoppingList from '../../pages/shoppinglist/shoppinglist.component';
import MyShoppingList from '../../pages/shoppinglist/shoppinglistMy.component';
import ActiveListItem from '../../pages/shoppinglist/activeListItem.component';
import ActiveListItemEdit from '../../pages/shoppinglist/activeListItemEdit.component';
import NewShoppingList from '../../pages/shoppinglist/shoppinglistNew.component';

import './main.styles.scss';

const Main = () => {
  return (
    <main>
      <Container style={{ paddingBottom: '1rem' }}>
        <Routes>
          <Route index path="/" element={<Home />} />

          <Route path="recipes/me" element={<MyRecipes />} />
          <Route path="recipes/new" element={<NewRecipe />} />
          <Route path="recipes/:recipeId" element={<ViewRecipe />} />
          <Route path="recipes/:recipeId/edit" element={<EditRecipe />} />

          <Route path="shoppinglist" element={<ShoppingList />}>
            <Route path="new" element={<NewShoppingList />} />
            <Route path="me" element={<MyShoppingList />} />

            <Route path="active" element={<ActiveListItem />} />
            <Route path="active/edit" element={<ActiveListItemEdit />} />
            <Route index element={<MyShoppingList />} />
          </Route>
        </Routes>
      </Container>

      <LoginDialog />
    </main>
  );
};
export default Main;
