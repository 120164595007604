export const foodTags = [
  {
    id: 'starter',
    name: 'recipes:starter',
  },
  {
    id: 'main',
    name: 'recipes:mainDish',
  },
  {
    id: 'dessert',
    name: 'recipes:dessert',
  },
];

export const recipeLanguages = [
  {
    id: '',
    name: 'translation:none',
  },
  {
    id: 'en',
    name: 'translation:en',
  },
  {
    id: 'fi',
    name: 'translation:fi',
  },
];
