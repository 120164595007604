import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { removeRecipe } from '../../redux/myRecipe/myRecipe.slice';
import { selectToken } from '../../redux/auth/auth.slice';

import urls, { deleteData, getData } from '../../utils/static';

import { Grid, Button, Alert } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Spinner from '../../components/spinner/spinner.component';

import RecipeView from '../../components/viewRecipe/viewRecipe.component';

const ViewRecipe = () => {
  const { t } = useTranslation(['recipes', 'translation']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const { state } = useLocation();
  const { recipeId } = useParams();

  const [recipe, setRecipe] = useState(state);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const deleteRecipe = async () => {
    setDeleteLoading(true);
    setDeleteError(false);

    try {
      const res = await deleteData(urls.recipes.deleteRecipe + recipe._id, {
        Authorization: `Bearer ${token}`,
      });
      setDeleteLoading(false);
      setDeleteDialogOpen(false);
      dispatch(removeRecipe(res._id));
      navigate('/recipes/me');
    } catch (error) {
      setDeleteError(true);
      setDeleteLoading(false);
      setDeleteDialogOpen(false);
    }
  };

  useEffect(() => {
    const loadRecipe = (id) => {
      if (loading) {
        return;
      }
      setLoading(true);
      setError(false);

      getData(urls.recipes.getRecipe + id)
        .then((res) => {
          setRecipe(res);
          setLoading(false);
        })
        .catch((err) => {
          setError(true);
          setLoading(false);
        });
    };

    if (!recipe && !error) {
      loadRecipe(recipeId);
    }
  }, [recipe, error, recipeId, loading]);

  return (
    <React.Fragment>
      <Grid container gap={6} style={{ marginTop: '2rem' }}>
        {error ? (
          <Alert severity="error">{t('recipeLoadFailed')}</Alert>
        ) : !recipe ? (
          <Grid item sx={{ flex: '1' }}>
            <Spinner />
          </Grid>
        ) : (
          <React.Fragment>
            {deleteError ? <Alert severity="error">{t('failedToDeleteRecipe')}</Alert> : null}
            <RecipeView
              recipe={recipe}
              onRecipeDelete={() => {
                setDeleteDialogOpen(true);
              }}
            />
          </React.Fragment>
        )}
      </Grid>

      <Dialog open={deleteDialogOpen} maxWidth="sm" fullWidth>
        <DialogTitle id="alert-dialog-title">{t('deleteRecipe')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t('doYouReallyWantToDeleteRecipe')}</DialogContentText>
          {deleteLoading ? <Spinner /> : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteRecipe} autoFocus variant="contained" color="error">
            {t('translation:delete')}
          </Button>
          <Button onClick={handleDialogClose}>{t('translation:cancel')}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default ViewRecipe;
