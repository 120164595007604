import { createSlice, createSelector } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'app',
  initialState: {
    showLoginDialog: false,
    loginDialogTab: 'login', // login || signup
  },
  reducers: {
    openDialog: (state, action) => {
      state.showLoginDialog = true;
      state.loginDialogTab = action.payload;
    },
    closeDialog: (state) => {
      state.showLoginDialog = false;
    },
    setLoginDialogTab: (state, action) => {
      state.loginDialogTab = action.payload;
    },
  },
});

export const { openDialog, closeDialog, setLoginDialogTab } = slice.actions;

const getApp = (state) => state.app;
export const selectLoginDialogOpen = createSelector([getApp], (app) => app.showLoginDialog);
export const selectLoginDialogTab = createSelector([getApp], (app) => app.loginDialogTab);

export default slice.reducer;
