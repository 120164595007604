import { createTheme } from '@mui/material/styles';
import { orange, purple } from '@mui/material/colors';

const getTheme = (isDark) => {
  const palette = {
    primary: orange,
    secondary: purple,
    mode: isDark ? 'dark' : 'light',
  };
  return createTheme({
    palette: palette,
    typography: {
      fontFamily: [
        'Roboto',
        '-apple-system',
        'BlinkMacSystemFont',
        'Segoe UI',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        'sans-serif',
      ].join(','),
    },
  });
};

export default getTheme;
