import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Spinner from '../components/spinner/spinner.component';
import { useFormik, FieldArray, FormikProvider, FastField } from 'formik';
import { TextField } from 'formik-mui';
import * as yup from 'yup';

import { recipeLanguages, foodTags } from '../utils/recipe';

import { Button, Grid, Typography, Box, MenuItem, FormControl, InputLabel, Select, Chip, Alert } from '@mui/material';
import SlateTextEditorField from '../components/textEditor/textEditorField.component';
import { initialValue as slateInitialValue } from '../components/textEditor/editor.utils';

const ingrediant = {
  amount: '',
  unit: '',
  name: '',
  productUrl: '',
};

const ingrediants = [ingrediant, ingrediant, ingrediant, ingrediant];

export const initialRecipeValues = {
  name: '',
  description: '',
  language: '',
  tags: [],
  serves: '',
  cookTimeMins: '',
  ingrediants,
  instructionAndNotes: JSON.stringify(slateInitialValue),
};

const RecipeForm = ({ onSave, onCancel, loading, errorText, initialValues }) => {
  const { t } = useTranslation(['forms', 'translation', 'recipes']);
  const size = 'small';

  const validationSchema = yup.object({
    name: yup.string().required(t('required')).max(100, t('maxLenght100')),
    description: yup.string().max(250, t('maxLenght250')),
  });

  if (initialValues) {
    initialValues.ingrediants.forEach((ing) => {
      ing.amount = ing.amount ?? '';
    });
    initialValues.serves = initialValues.serves ?? '';
    initialValues.cookTimeMins = initialValues.cookTimeMins ?? '';
  }
  const formik = useFormik({
    initialValues: initialValues ?? initialRecipeValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSave(values);
    },
    validateOnChange: false,
  });

  useEffect(() => {
    if (errorText && formik.isSubmitting) {
      formik.setSubmitting(false);
    }
  }, [errorText, formik]);

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" component="div" gutterBottom style={{ marginTop: '1rem' }}>
                {t('titleRecipeInfo')}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <FastField fullWidth required size={size} label={t('name')} name="name" component={TextField}></FastField>
            </Grid>

            <Grid item xs={3}>
              <FormControl size={size} fullWidth>
                <InputLabel id="recipe-language-label">{t('language')}</InputLabel>
                <Select
                  labelId="recipe-language-label"
                  id="recipe-language"
                  name="language"
                  label={t('language')}
                  value={formik.values.language}
                  onChange={formik.handleChange}
                >
                  {recipeLanguages.map((lng) => (
                    <MenuItem key={lng.id} value={lng.id}>
                      {t(lng.name)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <FormControl size={size} fullWidth>
                <InputLabel id="recipe-foogtags-label">{t('foodTags')}</InputLabel>
                <Select
                  labelId="recipe-foogtags-label"
                  id="recipe-foogtags"
                  name="tags"
                  label={t('foodTags')}
                  multiple
                  value={formik.values.tags}
                  onChange={formik.handleChange}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip size="small" key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  {foodTags.map((tag) => (
                    <MenuItem key={tag.id} value={tag.id}>
                      {t(tag.name)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FastField
                fullWidth
                size={size}
                multiline
                label={t('description')}
                name="description"
                component={TextField}
              ></FastField>
            </Grid>

            <Grid item xs={6}>
              <FastField
                fullWidth
                size={size}
                type="number"
                label={t('serves')}
                name="serves"
                component={TextField}
              ></FastField>
            </Grid>
            <Grid item xs={6}>
              <FastField
                fullWidth
                size={size}
                type="number"
                label={t('cookTimeMins')}
                name="cookTimeMins"
                component={TextField}
              ></FastField>
            </Grid>
          </Grid>

          {/* ingrediants */}
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" component="div" gutterBottom style={{ marginTop: '2rem' }}>
                {t('ingredients')}
              </Typography>
            </Grid>

            <FieldArray name="ingrediants" validateOnChange={false}>
              {({ push, remove, form }) => {
                return (
                  <Grid item container xs={12} gap={4}>
                    {form.values.ingrediants.map((ing, index) => {
                      return (
                        <Grid item container spacing={1} key={index} xs={12}>
                          <Grid item container spacing={1} xs={11}>
                            <Grid item xs={2}>
                              <FastField
                                fullWidth
                                size={size}
                                type="number"
                                label={t('amount')}
                                name={`ingrediants.${index}.amount`}
                                component={TextField}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <FastField
                                fullWidth
                                size={size}
                                label={t('unit')}
                                name={`ingrediants.${index}.unit`}
                                component={TextField}
                              />
                            </Grid>
                            <Grid item xs={8}>
                              <FastField
                                fullWidth
                                size={size}
                                label={t('name')}
                                name={`ingrediants.${index}.name`}
                                component={TextField}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FastField
                                fullWidth
                                tabIndex={-1}
                                size={size}
                                placeholder={t('ingrediantUrlPlaceHolder')}
                                label={t('ingrediantUrl')}
                                name={`ingrediants.${index}.productUrl`}
                                component={TextField}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={1}>
                            <Button
                              tabIndex={-1}
                              fullWidth
                              sx={{ margin: 0, minWidth: 0, height: '100%' }}
                              color="secondary"
                              variant="outlined"
                              onClick={() => remove(index)}
                            >
                              X
                            </Button>
                          </Grid>
                        </Grid>
                      );
                    })}
                    <Button tabIndex={-1} type="button" variant="outlined" onClick={() => push({ ...ingrediant })}>
                      {t('translation:add')}
                    </Button>
                  </Grid>
                );
              }}
            </FieldArray>
          </Grid>

          {/* instructionAndNotes */}
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" component="div" gutterBottom style={{ marginTop: '2rem' }}>
                {t('instructionsAndNotes')}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <FastField
                name="instructionAndNotes"
                placeholder={t('enterInstructionsAndNotes')}
                fullWidth
                initValue={formik.values.instructionAndNotes}
                textContainerStyle={{
                  minHeight: 150,
                }}
                label={t('instructionsAndNotes')}
                component={SlateTextEditorField}
              ></FastField>
            </Grid>
          </Grid>

          {errorText ? (
            <Grid item container sx={{ marginTop: 1 }}>
              {errorText ? <Alert severity="error">{errorText}</Alert> : null}
            </Grid>
          ) : null}

          {/* buttons */}
          <Grid item container alignItems="center">
            <Grid item>
              <Button
                sx={{ marginTop: 3, marginBottom: 3 }}
                variant="contained"
                color="primary"
                onClick={formik.submitForm}
              >
                {t('translation:save')}
              </Button>
            </Grid>
            <Grid item>
              <Button sx={{ margin: 2 }} variant="outlined" color="secondary" onClick={formik.handleReset}>
                {t('translation:reset')}
              </Button>
            </Grid>
            {/* <Grid item>
              <Button
                sx={{ margin: 2 }}
                variant="outlined"
                color="secondary"
                onClick={() => formik.resetForm({ values: initialRecipeValues })}
              >
                {t('translation:clear')}
              </Button>
            </Grid> */}
            <Grid item>
              <Button variant="outlined" color="secondary" onClick={() => onCancel()}>
                {t('translation:cancel')}
              </Button>
            </Grid>
            <Grid item>{loading ? <Spinner></Spinner> : null}</Grid>
          </Grid>
        </Grid>

        {/* <pre>{JSON.stringify(formik.values, null, 2)}</pre> */}
      </form>
    </FormikProvider>
  );
};

export default RecipeForm;
