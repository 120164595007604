import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { selectUser } from '../../redux/auth/auth.slice';

import { Typography, Grid, Button, IconButton, Tooltip } from '@mui/material';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import IngrediantsList from '../../components/recipeList/ingrediantsList.component';
import AddRecipeToShoppingList from '../shoppingList/addRecipeShoppingList.component';
import TextEditorView from '../../components/textEditor/textEditorView.component';

const ViewRecipe = ({ recipe, onRecipeDelete }) => {
  const { t } = useTranslation(['recipes', 'translation']);
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [open, setOpen] = useState(false);

  const AddShoppingList = () => {
    setOpen(true);
  };

  const onDeleteClicked = () => {
    onRecipeDelete(recipe);
  };

  const editRecipe = () => {
    navigate('edit', { state: recipe });
  };

  return (
    <React.Fragment>
      <Grid item container gap={2}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h4">{recipe.name}</Typography>
          </Grid>

          <Grid item>
            <Grid container gap={2} alignItems="center">
              <Grid item>
                <Tooltip title={t('translation:addShoppinglist')}>
                  <IconButton onClick={AddShoppingList}>
                    <PlaylistAddIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              {user?._id === recipe?.creator._creatorId ? (
                <React.Fragment>
                  <Grid item>
                    <Button size="small" variant="contained" color="primary" onClick={editRecipe}>
                      {t('translation:edit')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button size="small" variant="contained" color="error" onClick={() => onDeleteClicked()}>
                      {t('translation:delete')}
                    </Button>
                  </Grid>
                </React.Fragment>
              ) : null}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={8}>
          <Typography variant="subtitle1">{recipe.description}</Typography>
        </Grid>
      </Grid>

      <Grid item container spacing={3}>
        <Grid item container md={6}>
          <Grid item container xs={12} gap={3} sx={{ alignItems: 'center' }}>
            <Typography variant="h6" gutterBottom>
              {t('ingredients')}
            </Typography>
            {recipe.serves ? (
              <Typography variant="body2" gutterBottom>
                {`${recipe.serves} ${t('serves')}`}
              </Typography>
            ) : null}
          </Grid>

          <IngrediantsList ingrediants={recipe.ingrediants} />
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid item container xs={12} gap={3} sx={{ alignItems: 'center' }}>
            <Typography variant="h6" gutterBottom>
              {t('cooking')}
            </Typography>
            {recipe.cookTimeMins ? (
              <Typography variant="body2" gutterBottom>
                {`${t('aboutMins', { val: recipe.cookTimeMins })}`}
              </Typography>
            ) : null}
          </Grid>

          <TextEditorView
            initValue={recipe.instructionAndNotes}
            placeholder={t('noInstructionsOrNotes')}
          ></TextEditorView>
        </Grid>
      </Grid>

      {open ? <AddRecipeToShoppingList recipe={recipe} onClose={() => setOpen(false)} /> : null}
    </React.Fragment>
  );
};
export default ViewRecipe;
