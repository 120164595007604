import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import urls, { putData } from '../../utils/static';

import { selectToken } from '../../redux/auth/auth.slice';
import { updateRecipe } from '../../redux/myRecipe/myRecipe.slice';

import RecipeForm from '../../forms/recipeForm.component';

const EditRecipe = ({ recipe }) => {
  const { t } = useTranslation(['translation', 'recipes']);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const [errorText, setErrorText] = useState('');
  const [loading, setLoading] = useState(false);

  const editRecipe = async (values) => {
    if (loading) {
      return;
    }
    setLoading(true);
    setErrorText('');

    try {
      const res = await putData(urls.recipes.editRecipe + recipe._id, values, { Authorization: `Bearer ${token}` });
      setLoading(false);
      dispatch(updateRecipe(res));
      navigate(`/recipes/${res._id}`, { state: res });
    } catch (error) {
      setLoading(false);
      if (error.status === 401) {
        setErrorText(t('translation:error401'));
      } else {
        setErrorText(t('recipes:recipeSaveFailed'));
      }
    }
  };

  const cancelCreating = () => {
    navigate(-1);
  };

  return (
    <RecipeForm
      initialValues={recipe}
      onSave={editRecipe}
      onCancel={cancelCreating}
      loading={loading}
      errorText={errorText}
    />
  );
};
export default EditRecipe;
