import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from '../redux/auth/auth.slice';

import Spinner from '../components/spinner/spinner.component';
import { useFormik, FieldArray, FormikProvider, FastField } from 'formik';
import { TextField } from 'formik-mui';
import * as yup from 'yup';

import { Button, Grid, Typography, Alert } from '@mui/material';

const ingrediant = {
  amount: '',
  unit: '',
  name: '',
  productUrl: '',
};

export const initialRecipeValues = {
  name: '',
  ingrediants: [ingrediant, ingrediant, ingrediant, ingrediant],
};

const ShoppingListForm = ({ onSave, onCancel, loading, errorText, initialValues, hideDraft }) => {
  const user = useSelector(selectUser);
  const { t } = useTranslation(['forms', 'translation']);
  const [saveAsDraft, setSaveAsDraft] = useState(false);
  const size = 'small';

  const validationSchema = yup.object({
    name: yup.string().max(100, t('maxLenght100')),
  });

  if (initialValues) {
    initialValues.ingrediants.forEach((ing) => {
      ing.amount = ing.amount ?? '';
    });
  }
  const formik = useFormik({
    initialValues: initialValues ?? initialRecipeValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSave(values, saveAsDraft);
    },
    validateOnChange: false,
  });

  useEffect(() => {
    if (errorText && formik.isSubmitting) {
      formik.setSubmitting(false);
    }
  }, [errorText, formik]);

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <FastField fullWidth size={size} label={t('name')} name="name" component={TextField}></FastField>
            </Grid>
          </Grid>

          {/* ingrediants */}
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" component="div" gutterBottom style={{ marginTop: '2rem' }}>
                {t('ingredients')}
              </Typography>
            </Grid>

            <FieldArray name="ingrediants" validateOnChange={false}>
              {({ push, remove, form }) => {
                return (
                  <Grid item container xs={12} gap={4}>
                    {form.values.ingrediants.map((ing, index) => {
                      return (
                        <Grid item container spacing={1} key={index} xs={12}>
                          <Grid item container spacing={1} xs={11}>
                            <Grid item xs={2}>
                              <FastField
                                fullWidth
                                size={size}
                                type="number"
                                label={t('amount')}
                                name={`ingrediants.${index}.amount`}
                                component={TextField}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <FastField
                                fullWidth
                                size={size}
                                label={t('unit')}
                                name={`ingrediants.${index}.unit`}
                                component={TextField}
                              />
                            </Grid>
                            <Grid item xs={8}>
                              <FastField
                                fullWidth
                                size={size}
                                label={t('name')}
                                name={`ingrediants.${index}.name`}
                                component={TextField}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FastField
                                fullWidth
                                tabIndex={-1}
                                size={size}
                                placeholder={t('ingrediantUrlPlaceHolder')}
                                label={t('ingrediantUrl')}
                                name={`ingrediants.${index}.productUrl`}
                                component={TextField}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={1}>
                            <Button
                              tabIndex={-1}
                              fullWidth
                              sx={{ margin: 0, minWidth: 0, height: '100%' }}
                              color="secondary"
                              variant="outlined"
                              onClick={() => remove(index)}
                            >
                              X
                            </Button>
                          </Grid>
                        </Grid>
                      );
                    })}
                    <Button tabIndex={-1} type="button" variant="outlined" onClick={() => push({ ...ingrediant })}>
                      {t('translation:add')}
                    </Button>
                  </Grid>
                );
              }}
            </FieldArray>
          </Grid>

          {errorText ? (
            <Grid item container sx={{ marginTop: 1 }}>
              {errorText ? <Alert severity="error">{errorText}</Alert> : null}
            </Grid>
          ) : null}

          {/* buttons */}
          <Grid item container alignItems="center" gap={1}>
            {user ? (
              <Grid item>
                <Button
                  sx={{ marginTop: 3, marginBottom: 3 }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setSaveAsDraft(false);
                    formik.submitForm();
                  }}
                >
                  {t('translation:save')}
                </Button>
              </Grid>
            ) : null}
            {hideDraft ? null : (
              <Grid item>
                <Button
                  sx={{ marginTop: 3, marginBottom: 3 }}
                  variant={user ? 'outlined' : 'contained'}
                  color="primary"
                  onClick={() => {
                    setSaveAsDraft(true);
                    formik.submitForm();
                  }}
                >
                  {t('translation:saveAsDraft')}
                </Button>
              </Grid>
            )}

            <Grid item>
              <Button variant="outlined" color="secondary" onClick={formik.handleReset}>
                {t('translation:reset')}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="secondary" onClick={() => onCancel()}>
                {t('translation:cancel')}
              </Button>
            </Grid>
            <Grid item>{loading ? <Spinner></Spinner> : null}</Grid>
          </Grid>
        </Grid>

        {/* <pre>{JSON.stringify(formik.values, null, 2)}</pre> */}
      </form>
    </FormikProvider>
  );
};

export default ShoppingListForm;
