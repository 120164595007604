import { createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { persistStore } from 'redux-persist';
import logger from 'redux-logger';

import rootReducer, { rootEpic } from './root-reducer';

const epicMiddleware = createEpicMiddleware();

const middlewares = [epicMiddleware];

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

export const store = createStore(rootReducer, applyMiddleware(...middlewares));

epicMiddleware.run(rootEpic);

export const persistor = persistStore(store);
