import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { map, switchMap, takeUntil, catchError, filter, take } from 'rxjs/operators';

import urls, { postData } from '../../utils/static';
import { signup, signupCancel, signupFullfilled, signupRejected } from './auth.slice';

const signUpEpic = (action$) =>
  action$.pipe(
    ofType(signup.type),
    map((action$) => {
      return {
        username: action$?.payload?.username,
        password: action$?.payload?.password,
        email: action$?.payload?.email,
      };
    }),
    switchMap((signupData) =>
      from(postData(urls.signup, signupData)).pipe(
        map((response) => fetchFullfilled(response)),
        takeUntil(
          action$.pipe(
            filter((action) => action.type === signupCancel.type),
            take(1)
          )
        ),
        catchError((error) =>
          of({
            type: signupRejected.type,
            payload: error,
            error: true,
          })
        )
      )
    )
  );

const fetchFullfilled = (payload) => ({
  type: signupFullfilled.type,
  payload: payload,
});

export default signUpEpic;
